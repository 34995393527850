import Vue from 'vue'
import Axios from 'axios'
import appSettings from '@/plugins/appSettings'
import { JobApplication } from '@/models/JobApplication'
import { TalentApplyConfiguration } from '@/models/TalentApplyConfiguration'

Vue.use(appSettings)
const ROUTE = `${Vue.appSettings.talentApplyApiBaseUrl}/talentapply`

export default {
  calculateJobApplicationFiability (jobApplication: JobApplication) {
    const body = {
      JobApplicationId: jobApplication.Id,
      FirstName: jobApplication.Applicant.FirstName,
      LastName: jobApplication.Applicant.LastName,
      Email: jobApplication.Applicant.Email,
      Attachments: jobApplication.Attachments
    }
    return Axios.post(`${ROUTE}/fiability`, body)
      .then(response => {
        return response.data
      })
  },
  publishJobApplicationToATS (jobApplication: JobApplication, companyId: number, targetAts: string) {
    if (targetAts === null) {
      return Promise.reject(new Error('TargetAts is null'))
    }

    const body = {
      TargetAts: targetAts,
      LegacyCompanyId: companyId,
      JobApplicationId: jobApplication.Id,
      JobOfferReference: jobApplication.JobOffer.Reference,
      SourceJobBoardName: jobApplication.Source,
      Data: {
        FirstName: jobApplication.Applicant.FirstName,
        LastName: jobApplication.Applicant.LastName,
        Email: jobApplication.Applicant.Email,
        Attachments: jobApplication.Attachments
      }
    }
    return Axios.post(`${ROUTE}/publish`, body)
      .then(response => {
        return response.data.data
      })
  },
  getTalentApplyConfiguration (companyId: number) {
    return Axios.get(`${ROUTE}/${companyId}`)
      .then(response => {
        return new TalentApplyConfiguration().initialize(response.data)
      })
  }
}
