<template>
  <section id='advanced_filter_panel'>
    <div class='filters-container'>
      <InputAutocomplete
        :autoCompleteValues='filterReferential.ApplicantFullnames'
        className='filter-input'
        placeholder='Nom'
        @valueSelected='filterOnApplicantFullname'
      />
      <InputAutocomplete
        :autoCompleteValues='filterReferential.JobTitles'
        className='filter-input'
        placeholder='Poste'
        @valueSelected='filterOnJobTitle'
      />
      <div class='filter-input dual-filter'>
        <SelectList
        :options='minRatingOptions'
        :headerOptionLabel="'Évaluation min'"
        :resetValueAtSelection='true'
        @onChange='filterOnMinRating'
        :class="'dual-filter-element'"
        />
        <SelectList
          :options='maxRatingOptions'
          :headerOptionLabel="'Évaluation max'"
          :resetValueAtSelection='true'
          @onChange='filterOnMaxRating'
          :class="'dual-filter-element'"
        />
      </div>
    </div>
    <div class='filters-container'>
      <InputAutocomplete
        :autoCompleteValues='filterReferential.JobOfferReferences'
        className='filter-input'
        placeholder='Référence'
        @valueSelected='filterOnJobOfferReference'
      />
      <InputAutocomplete
        :autoCompleteValues='filterReferential.Sources'
        className='filter-input'
        placeholder='Source'
        @valueSelected='filterOnSource'
      />
    </div>
    <div class='filters-container'>
      <InputAutocomplete
        :autoCompleteValues='filterReferential.Tags'
        :minimumCharsBeforeAutoComplete="0"
        className='filter-input'
        placeholder='Tag'
        @valueSelected='filterOnTag'
      />
      <SelectList
        :name="'recruitmentStepAndStatusSelect'"
        :options='stepAndStatusSelectOptions'
        :headerOptionLabel="'Étape de recrutement / Statut'"
        :resetValueAtSelection='true'
        @onChange='filterOnStepAndStatus'
      />
    </div>
    <div class='filters-container'>
      <div class='filter-input'>
        <InputAutocomplete
        :autoCompleteValues='filterReferential.JobLocations'
        className='filter-input'
        placeholder='Localisation'
        @valueSelected='filterOnJobLocation'
      />
      </div>
      <div class='filter-input dual-filter'>
        <!-- TODO : voir si rencapsuler le v-date-picker dans un composant perso est une bonne idée ou non -->
        <v-date-picker
          class='dual-filter-element'
          :locale='locale'
          :min-date='minDate'
          :max-date='fromDate'
          v-model='fromDateValue'
          @popoverWillHide="filterFromDate(fromDateValue)"
          :popover="{ placement: 'bottom', visibility: 'click' }"
          :input-props="{
            class: 'input-text-default',
            placeholder: 'À partir du',
            readonly: true,
          }"
        >
          <input
            slot-scope="{ inputProps, inputEvents }"
            :class="'input-text-default'"
            v-bind='inputProps'
            v-on='inputEvents'
          />
        </v-date-picker>
        <v-date-picker
          class='dual-filter-element'
          :locale='locale'
          :min-date='toDate'
          :max-date='maxDate'
          v-model='toDateValue'
          @popoverWillHide="filterToDate(toDateValue)"
          :popover="{ placement: 'bottom', visibility: 'click' }"
          :input-props="{
            class: 'input-text-default',
            placeholder: 'Jusqu\'au',
            readonly: true,
          }"
        >
          <input
            slot-scope='{ inputProps, inputEvents }'
            :class="'input-text-default'"
            v-bind='inputProps'
            v-on='inputEvents'
          />
        </v-date-picker>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import SelectList from '@/components/_generic/SelectList'
import InputAutocomplete from '@/components/_generic/InputAutocomplete'
import { minRatingLimit, maxRatingLimit } from '@/mixins/constants'

export default {
  name: 'AdvancedFilter',
  components: {
    SelectList,
    InputAutocomplete
  },
  data () {
    return {
      toDateValue: '',
      fromDateValue: '',
      minRating: minRatingLimit,
      maxRating: maxRatingLimit
    }
  },
  methods: {
    ...mapActions(['filterJobApplications']),
    filterOnApplicantFullname (name) {
      const changedFilters = this.filters
      changedFilters.ApplicantFullname = name
      this.filterJobApplications(changedFilters)
    },
    filterOnJobTitle (jobTitle) {
      const changedFilters = this.filters
      changedFilters.JobTitle = jobTitle
      this.filterJobApplications(changedFilters)
    },
    filterOnJobOfferReference (jobOfferReference) {
      const changedFilters = this.filters
      changedFilters.JobOfferReference = jobOfferReference
      this.filterJobApplications(changedFilters)
    },
    filterOnSource (source) {
      const changedFilters = this.filters
      changedFilters.Source = source
      this.filterJobApplications(changedFilters)
    },
    filterOnJobLocation (jobLocation) {
      const changedFilters = this.filters
      changedFilters.JobLocation = jobLocation
      this.filterJobApplications(changedFilters)
    },
    filterOnTag (tags) {
      const changedFilters = this.filters
      const tagList = tags.split(/[^A-Za-z0-9]/)
      tagList.forEach((tag) => {
        if (changedFilters.Tags.indexOf(tag) < 0) {
          changedFilters.Tags.push(tag)
        }
      })
      this.filterJobApplications(changedFilters)
    },
    filterToDate (date) {
      if (date) {
        const changedFilters = this.filters
        changedFilters.ToDate = this.$moment(date).format('YYYY-MM-DD')
        this.filterJobApplications(changedFilters)
        this.toDateValue = ''
      }
    },
    filterFromDate (date) {
      if (date) {
        const changedFilters = this.filters
        changedFilters.FromDate = this.$moment(date).format('YYYY-MM-DD')
        this.filterJobApplications(changedFilters)
        this.fromDateValue = ''
      }
    },
    filterOnStepAndStatus (id) {
      const changedFilters = this.filters
      const status = this.statuses.find(x => x.Id === id.currentSelectedValue)
      const step = this.recruitmentSteps.find(x => x.Id === id.currentSelectedValue)
      if (status) {
        changedFilters.Status = status.Id
      } else if (step) {
        changedFilters.RecruitmentStep = step.Id
      }
      this.filterJobApplications(changedFilters)
      if (id.event) {
        id.event.target.value = ''
        id.event.target.blur()
      }
    },
    filterOnMinRating (selectedMinRating) {
      const changedFilters = this.filters
      changedFilters.MinRating = selectedMinRating.currentSelectedValue
      this.filterJobApplications(changedFilters)
    },
    filterOnMaxRating (selectedMaxRating) {
      const changedFilters = this.filters
      changedFilters.MaxRating = selectedMaxRating.currentSelectedValue
      this.filterJobApplications(changedFilters)
    }
  },
  watch: {
    'filters.MinRating' () {
      this.minRating = this.filters.MinRating ?? minRatingLimit
    },
    'filters.MaxRating' () {
      this.maxRating = this.filters.MaxRating ?? maxRatingLimit
    }
  },
  computed: {
    ...mapGetters([
      'jobApplications',
      'recruitmentSteps',
      'statuses',
      'filters',
      'filterReferential'
    ]),
    stepAndStatusSelectOptions () {
      const selectOptions = []
      this.statuses.forEach(s => selectOptions.push({ Value: s.Id, Label: s.Label }))
      const recruitmentSteps = this.recruitmentSteps
      recruitmentSteps.sort(rs => rs.Order).forEach(rs => selectOptions.push({ Value: rs.Id, Label: rs.Name }))
      return selectOptions
    },
    minDate () {
      return this.$moment(this.filterReferential.MinimumDate).format('YYYY-MM-DD')
    },
    maxDate () {
      return this.$moment(this.filterReferential.MaximumDate).format('YYYY-MM-DD')
    },
    fromDate () {
      return this.filters.ToDate ? this.filters.ToDate : this.maxDate
    },
    toDate () {
      return this.filters.FromDate ? this.filters.FromDate : this.minDate
    },
    recruitmentStepsValues () {
      const steps = this.recruitmentSteps
      const sorted = steps.sort(function (a, b) {
        return a.Order - b.Order
      })
      const values = []
      sorted.forEach((step) => {
        values.push({ Value: step.Id, Label: step.Name })
      })
      return values
    },
    minRatingOptions () {
      const minRatingValues = []
      for (let i = minRatingLimit; i <= this.maxRating; i++) {
        minRatingValues.push({ Value: i, Label: i })
      }

      return minRatingValues
    },
    maxRatingOptions () {
      const maxRatingValues = []
      for (let i = this.minRating; i <= maxRatingLimit; i++) {
        maxRatingValues.push({ Value: i, Label: i })
      }

      return maxRatingValues
    },
    locale () {
      return this.$appSettings.locale
    }
  }
}
</script>
